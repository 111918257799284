* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Sarabun, Helvetica, Arial, sans-serif;
    font-weight: 400;
}

:root {
    --background--primary: #011022;
    --background--primary-red: #EE0A24;
    --color-primary: white;
    --color-red: #EA5455;
    --color-green: #28C76F;
    --color-gray: #1c233b;
    --color-green2: #30837b;
    --color-black: black;
    --color-brown: #94886D;
    --color-blandGreen: #31BAA0;
    --color-violet: #B9C7FF;
    --color-blandBlude: #3EC9D6;
    --color-red2: #B11922;
    --color-green3: #3EC106;
    --color-gray2: #F1F3F4;
    --color-blue: #004AB1;
}

a {
    text-decoration: none;
}
.color-red-primary{
    color: var(--background--primary-red);
}
.bg-red-primary{
    background-color: var(--background--primary-red);
}
.bg-gray2 {
    background-color: var(--color-gray2);
}

.color-blue {
    color: var(--color-blue);
}

.color-green3 {
    color: var(--color-green3);
}

.bg-green3 {
    background-color: var(--color-green3);
}

p,
i,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

.position-relative {
    position: relative;
}
.infor-user-detail{
    height: 230px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: url("../img/bg_member.jpg");
    z-index: 100;
}
.infor-money{
    background-color: var(--color-primary);
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 16px;
}

.infoBtn{
    background-color: #464e66;
    position: absolute;
    top: 76%;
    left: 11%;
    width: 79%;
    z-index: 150;
    height: 2.3rem;
    border-radius: 10px 10px 0 0;
}
.infoBtn a{
    color: white;
}
.bg-transparent {
    background: transparent;
}

.text-justify {
    text-align: justify;
}

.color-green-2 {
    color: var(--color-green2);
}
.color-red{
    color: var(--color-red);
}
.font-weight{
    font-weight: bold;
}
.bg-red {
    background-color: var(--color-red);
}
.color-red-2 {
    color: var(--color-red2);
}

.bg-red-2 {
    background-color: var(--color-red2);
}

.color-white {
    color: var(--color-primary) !important;
}

.placeholder-white::placeholder {
    color: var(--color-primary) !important;
}

.boder-none:focus {
    box-shadow: none;
}

.cursor-pointer {
    cursor: pointer;
}

.body-web {
    width: 100vw;
    max-width: 100%;
    height: auto;
    min-height: 100vh;
}

.bg-black {
    background-color: var(--color-black);
}

.bg-white {
    background-color: var(--color-primary);
}

.color-white {
    color: var(--color-primary);
}

.color-black {
    color: var(--color-black);
}

section.main-container {
    margin-top: 80px;
}

body {
    background-color: var(--color-primary);
}
.main-container, .with-center{
    width: 500px !important;
    max-width: 800px;
}
.container-pay{
    background-color: #F3F3F3;
    position: relative;
}
.flex-class {
    display: flex;
    align-items: center;
}

section.main-container {
    width: 100%;
}

/* .ps-sidebar-root {
    position: fixed !important;
    top: 80px;
    height: auto;
    left: 0;
    bottom: 0;
    z-index: 10;

} */

.main-custom {
    margin-top: 81px !important;
    margin-left: 121px !important;
}

@media (max-width: 767px) {
    .main-custom {
        margin-top: 0 !important;
        margin-left: 0 !important;
    }

    g.tick-value {
        display: none;
    }

    /* .semicircle-gauge svg{
        width: 100%;
    } */
}

.ps-menuitem-root.active,
.ps-menuitem-root a.active {
    color: var(--color-red2);
}

.css-ac4ouy>.ps-menu-button:hover {
    background: transparent !important;
}

.ps-menuitem-root.active a:hover,
.ps-menuitem-root.active a:focus {
    background: transparent;
}

.bg-primary-custom {
    background-color: var(--background--primary) !important;
}

.bg-red {
    background-color: var(--color-red) !important;
}

.bg-green {
    background-color: var(--color-green) !important;
}

.bg-gray {
    background-color: var(--color-gray) !important;
}

.color-green {
    color: var(--color-green2) !important;
}

.color-red {
    color: var(--color-red) !important;
}

.bg-brown {
    background-color: var(--color-brown) !important;
}

.bg-blandGreen {
    background-color: var(--color-blandGreen) !important;
}

.color-violet {
    color: var(--color-violet) !important;
}

.bg-blandBlude {
    background-color: var(--color-blandBlude) !important;
}

.height-100 {
    height: 100%;
}
main {
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 55px;
    margin-bottom: 60px;
    height: auto;
    max-height: calc(100% - 100px);
}
.icon-close-code {
    position: absolute;
    top: 10px;
    z-index: 100;
    right: 10px;
}

.height-auto {
    height: auto;
}
.box-navbar-bottom{
    position: fixed;
    height: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}
.code-notification li {
    list-style-type: none;
    padding: 10px 15px;
    font-size: 16px;
}

.code-notification li.actice {
    position: relative;
}

.excavator {
    border-radius: 10px;
}

.code-notification li.actice::after {
    position: absolute;
    bottom: -5px;
    left: 0;
    content: "";
    width: 100%;
    height: 3px;
    background-color: #75313c;
}

.pie-chart-container {
    width: 200px;
    height: 200px;
    margin: 0 auto
}

.data-board {
    background-color: #28384A;
    padding: 20px;
    height: auto;
    border-radius: 10px;
}

.net-profit {
    width: 100%;
    height: 130px;
    border-radius: 10px;
}

.font-icon-setting {
    font-size: 25px;
    font-weight: bold;
}

.time-input-picker {
    width: 200px;
    height: 40px;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 8px;
    color: white;
}

.time-input-picker::placeholder {
    color: white;
}

.search-time-data {
    border: none;
    outline: none;
    color: white;
    border-radius: 5px;
}

.box-header {
    height: 50px;
    font-size: 15px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

header{
    border-bottom: 1px solid #f1f1f1;
    box-shadow: 0px 2px 4px #ddd;
    padding: 0 0px;
}
header .logo{
    height: 40px;
}
header .auth{
    position: absolute;
    right: 0;
    top: 0;
}
.auth a{
    font-size: 14px;
    color: #454545;
}
.auth .links{
    width: 150px;
}

.select-chane-money {
    color: var(--color-primary) !important;
}

.select-chane-money option {
    background-color: var(--background--primary);
    color: var(--color-primary);
    padding: 5px 0;
}

.select-chane-money:focus {
    box-shadow: none !important;
}

.logo {
    padding: 0 10px;
    height: 100%;
}

.logo-img {
    height: 60px;
}

.image-tableLayer img{
    width: 80%;
}
.img-box-item-content{
    border-radius: 10px;
}
.list-user-content{

}
li{
    list-style: none;
}
a{
    text-decoration: none;
    color : var(--background--primary);
}
.list-features-user{
    background-color: var(--color-primary);
    border-radius: 15px;
    padding-left: 0;
}
.list-features-user p{
    font-size: 1.2rem;
    padding-left: 10px;
}
.list-features-user li{
    border-bottom: 1px solid #f1f1f1;
}

.icon-box-item{
    position: relative;
}
.icon-box-item .icon-chirl{
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}
.btn-logout{
    padding: 16px 48px;
    border: none;
    outline: none;
    background: #F2413B;
    color: var(--color-primary);
    border-radius: 10px;
}
.list-data{
    background-color: var(--color-primary);
    border-radius: 10px;
}
.input-enter-money{
    width: 200px;
    height: 40px;
    border: none;
    outline: none;
    -moz-appearance: textfield;
    text-align: right;
}
.input-enter-money::-webkit-inner-spin-button,
.input-enter-money::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.btn-send-money{
    border: 10px;
    background-color: var(--color-red);
    color: var(--color-primary);
    border-radius: 10px;
}
.valid-feedback{
    display: block;
}
.login {
    z-index: 100;
}
.login-page{
    height: 100%;
    background-image: url('../img/z5736183186765_4a0930f09f6903af646212286b05d9b8.jpg');
    background-size: cover; /* Tùy chọn: Điều chỉnh kích thước ảnh để phủ toàn bộ phần tử */
    background-position: center; /* Tùy chọn: Canh giữa ảnh */
    background-repeat: no-repeat;
    border-radius: 10px;
}
.login .form-input {
    border-color: white;
    box-shadow :none;
}
.login .btn-login{
   border: none;
   outline: none;
   background-color: var(--background--primary-red) !important;
   color: var(--color-primary);
}
.titel-span-text:hover{
    cursor: pointer;
    color: var(--color-red);
    transition: 0.5s ease-in-out;    
}

.img-icon-user-page{
    width: 25px;
}
.titel-span-text.active{
    position: relative;
    font-weight: bold;  
    color: var(--background--primary-red) !important;
    transition: 0.5s ease-in-out;    
    cursor: pointer;
}
.titel-span-text.active::after{
    position: absolute;
    bottom: -10px;
    left: 0;
    content: "";
    width: 100%;
    height: 3px;
    transition: 0.5s ease-in-out;    
    background-color: var(--background--primary-red);
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}
/*.tabListLayer{*/
/*    background-color: var(--color-primary);*/
/*    border-radius: 10px;*/
/*}*/


.percent-project{
    font-size: 24px;
    font-weight: 400;
    color: #f2413b;
}
.invest-price{
    font-size: 24px;
    font-weight: 400;
    color: #f2413b;
}
.project-fund{
    font-size: 16px;
    color: #f2413b;
}
.btn-fund{
    border: none;
    box-shadow: none;
    outline: 0;
    background-color: #0074e0;
    color: #ffffff;
    text-align: center;
    padding: 4px 11px;
    font-size: 14px;
    border-radius: 20px;
}
.project-title{
    font-size: 22px;
    font-weight: 600;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #f3f3f3;
    margin: 0;
}
.project-card{
    margin-bottom: 15px;
    background: #ffffff;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 20px;
}

.pf-v5-c-progress__status {
    margin-left: auto;
    width: 50px;
    padding: 5px 10px;
    background-color: #0a95fc;
    color: white;
    border-radius: 20px;
    position: relative;
    display: inline-block;
}

.pf-v5-c-progress__status::after {
    content: '';
    position: absolute;
    bottom: -15px; /* Điều chỉnh vị trí theo yêu cầu */
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: #0a95fc transparent transparent transparent;
}


.Mui-selected{
    color: var(--background--primary-red) !important;
    filter: invert(25%) sepia(98%) saturate(7492%) hue-rotate(357deg) brightness(93%) contrast(115%);
}
.MuiBottomNavigationAction-label{
    font-size: 1rem!important;
}
.MuiBottomNavigation-root{
    border-top: 1px solid #e1e1e2;
}

.pf-v5-c-progress__bar::before{
    background-color: #0a95fc!important;
}

.pf-v5-c-progress__indicator{
    background-color: #ff4a23!important;
}

.image-tableLayer{
    background: #ffffff;
    border: 1px solid #eee;
    border-radius: 16px;
    padding: 20px;
    margin-bottom: 15px;
}
.home-card{
    padding: 15px;
}
.home-card > .fs-6{
    text-align: center;
    font-size: 14px!important;
    font-weight: 500;
    color: #4b5563;
}

.user-top-button img:first-child{
    height: 100%;
    width: 30px;
}
.user-top-button>img:nth-child(2){
    height: 45px;
}
.img-user{
    width: 50px;
    height: 50px;
    object-position: center;
    object-fit: cover;
    border-radius: 50%;
}

.img-chat {
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}
/* 
.list-user-chat {
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    overflow-y: scroll;
    height: 550px;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.list-user-chat::-webkit-scrollbar {
    width: 8px;
}

.list-user-chat::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.list-user-chat::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.list-user-chat::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.list-user-chat li {
    background-color: var(--color-gray2);
    cursor: pointer;
}
.list-user-chat li.active{
    background-color: var(--color-red);
    color: white;
    border-radius: 10px;
}
.list-message-chat{
    display: block;
    height: 500px;
    max-height: 600px;
   overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}
.list-message-chat::-webkit-scrollbar {
    width: 8px;
}

.list-user-chat::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.list-user-chat::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.list-user-chat::-webkit-scrollbar-thumb:hover {
    background: #555;
} */

.chat-infor{
    border: 1px solid #ccc;
    border-radius: 10px;
}
.item-message{
    width: 250px;
    padding: 10px 20px;
    height: auto;
    background-color: var(--color-gray);
    color: var(--color-primary);
    border-radius: 10px;
    margin: 10px;
}
.slider-img {
    height: 300px;
}
.slider-img img{
    overflow: hidden;
    object-fit: cover;
}
@media (min-width: 992px) { 
    .responsive-image {
        width: 50% !important; 
    }
}
.bank_number-input{
    height: 34px;
    border: 1px solid #ccc;
    overflow: hidden;
    border-radius: 5px;
}
.bank_number-input input{
    border: none;
    outline: none;
}
.icon-eye{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
}
.img-preview{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}
.text-input{
    border: none;
    outline: navy;
    padding: 0 50px;
}
.message-content {
    display: flex;
    align-items: center; /* Căn giữa theo chiều dọc */
    justify-content: center; /* Căn giữa theo chiều ngang */
    text-align: center; /* Căn giữa nội dung khi chỉ có một dòng */
    min-height: 50px; /* Đảm bảo rằng khối có chiều cao tối thiểu để căn giữa khi chỉ có một dòng */
    padding: 10px; /* Thêm khoảng cách giữa nội dung và khung chứa */
    white-space: pre-wrap; /* Đảm bảo nội dung sẽ xuống dòng khi cần */
    word-wrap: break-word; /* Đảm bảo từ dài sẽ được xuống dòng thay vì tràn khung */
}
