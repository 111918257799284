*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 16px;
    height: auto;
}
.chat{

}
.img-content{
    max-width: 200px;
}
.box-chat{
    position: relative;
    background: linear-gradient(157deg, rgb(147, 182, 255) 1.27%, rgb(175, 227, 252) 94.26%);
    overflow: hidden;
}
.box-chat .box-header, .box-chat .box-footer {
    position: absolute;
    left: 0;
    height: 100px;
    right: 0;
    z-index: 10;
  
}
.box-chat .box-header{
    top: 0;
}  
.box-chat .box-footer{
    bottom: 0;
    height: 100px;
}  
.img-header, .img-user{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
}
.box-content{
    max-width: 500px;
}
.infor-user{
    font-weight: bold;
    color: white;
}
.box-main{
    flex-direction: column-reverse;
     padding: 100px 30px;
    overflow: hidden;
    overflow-y: scroll;
}
.box-main::-webkit-scrollbar {
    width: 0px; 
    height: 12px;
}
.box-main::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
}
.box-main::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.box-main::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
}

.box-message-item{
    min-width: 150px;
    background-color: #FFFFFF;
    max-width: 300px;
    border-radius: 10px;
    position: relative;
    padding: 25px 35px;
}

.box-message-item  p{
    word-wrap: break-word;
}

.box-message-item small{
    position: absolute;
    font-size: 10px;
    font-weight: 100;
    top: 5px;
    right: 10px;
}
.btn-acction{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -30px;
}
.btn-acction-item{
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
}
.form-post{
    width: 80%;
    height: 50px;
    border: 1px solid #FFFFFF;
    background-color: #FFFFFF;
    overflow: hidden;
    border-radius: 26px;
    position: relative;
}
.form-post input{
    background: transparent;
    border: none;
    outline: none;
    padding-left: 50px;
}
.form-post button.btn-send , .form-post button.upload-file{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    outline: none;
 
    color: white;
}
.form-post button.btn-send {
    right: 10px;
    background-color: #E12823;
}   
.form-post button.upload-file{
    left: 10px;
    background: transparent;
    color: #555;
}
.list-user-chat{
    position: relative  !important;
}
.add-btn{
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    color: #555;
    width: 40px;
    height: 40px;
    border: 1px solid #555;
    border-radius: 50%;
    cursor: pointer;
}
.ps-sidebar-root.ps-sidebar-custorm{
    background-color: #9433C5;
    border-radius: 12px;
    overflow: hidden;
    color: white;
}
.css-1t8x7v1 >.ps-menu-button:hover {
    background: transparent !important;
}
.ps-menuitem-root.active{
    background-color: #9FCAFE;
    border-radius: 10px;
    color: white;
}
.ps-menuitem-root.active span{
    color: white;
}